import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SideBar from "../../components/SideBar";
import { Components } from "../../assets/Dimensions";
import SwitchComponent from "../../components/SwitchComponent";
import { ToastContainer, toast } from "react-toastify";
import "../questionVideoSolution/style.css";
import {
  getCourses,
  nestedTitles,
} from "../../api/QuestionSubCourseSelection/courses";
import Toggle from "../questionVideoSolution/components/toggle/toggle";
import { encryption, base64Encode } from "../../helpers/encryptionHelper";
import CourseBG from "../../assets/images/bg-lesson.jpeg";
import ExamCategoryType from "../../enums/ExamCategoryType";
import ExamType from "../../enums/ExamType";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { setCookie } from "../../helpers/cookie";
import QuestionPage from "../questionSubTopicSelection/QuestionPage";
import { useHistory } from "react-router-dom";
import {
  getAchievementCourses,
  getAchievementSubCourseTopics,
} from "../../api/QuestionAchivments/coursesAndQuestions";
import Modal from "../../components/cutomModal";
import Loader from "@material-ui/core/CircularProgress"
import { RatioBasedColor } from "../../util/ratioBasedColor";

const CoursePage = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const user = JSON.parse(localStorage.getItem("user"));
  const userAdminToken = sessionStorage.getItem("jwtP");

  const [nav, setNav] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedExamCategory, setSelectedExamCategory] = useState(JSON.parse(localStorage.getItem("SelectedExamCategory"))||null);
  const [coursesData, setCoursesData] = useState([]);
  const [allCourseData, setAllCourseData] = useState(null);
  const [examTypeId, setExamTypeId] = useState(JSON.parse(localStorage.getItem("selectedExamTypeId"))||1);
  const [subCoursesData, setSubCoursesData] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(JSON.parse(localStorage.getItem("selectedCourseId"))||null);
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);
  const [ratioAlert,setRatioAlert] = useState(false)
  const [loader,setLoader] = useState(false)

  const history = useHistory();

  const style = {
    container: {
      //state e bool değer ekle menü butonuna basıldığında css
      //içinde container a margin eklesin hemde navı açsın

      //marginLeft: isTabletOrMobile ? 0 : Components.SideBarWidth + 15,
      paddingLeft: isTabletOrMobile ? 15 : Components.SideBarWidth + 15,
      paddingTop: 20,
      paddingRight: 15,
      marginBottom: 20,
    },
    editIcon: {
      width: Components.Image.Big.Width,
      height: Components.Image.Big.Height,
    },
    iconButton: {
      marginLeft: 10,
      padding: 0,
    },
  };

  const RatioCalculate = (completedCount, totalCount) => {
    if (totalCount === 0) {
      return 0; // or return null, undefined, or any other value you prefer
    }
    return Math.round((completedCount / totalCount) * 100);
  };

  const goToQuestionPage = (subTopic) => {
    const getRatio = RatioCalculate(
      subTopic.CompletedCount,
      subTopic.TotalCount
    );
    console.log("getRatio", getRatio);
    if (getRatio >= 95) {
      setRatioAlert(true);
    } else {
      history.push({
        pathname: "/AchievementSelection",
        state: {
          selectedSubCourseData: subTopic,
        },
      });
    }
  };

  const getCategoryName = (value) => {
    for (const key in ExamCategoryType) {
      if (ExamCategoryType[key] === value) {
        return key;
      }
    }
    return null; // Return null if the value is not found
  };
  const getExamTypeName = (value) => {
    for (const key in ExamType) {
      if (ExamType[key] === value) {
        return key;
      }
    }
    return null; // Return null if the value is not found
  };

  const filterCoursesData = (data, categoryId, ExamTypeId) => {
    const category = data.find((category) => category.ExamType === ExamTypeId);
    if (!category) {
      console.log("Category not found");
      return;
    }

    // const examType = category.ExamCategoryCourses.find(
    //   (examType) => examType.ExamTypeId === ExamTypeId
    // );
    // if (!examType) {
    //   console.log("Exam type not found in the category");
    //   return;
    // }

    return setCoursesData(category.Courses);
  };

  const getCoursesData = async (value) => {
    setLoader(true);
    const selectedExamCategoryFromStore = JSON.parse(localStorage.getItem("SelectedExamCategory"));
    try {
      if (selectedExamCategory) {
        console.log("examTypeId",examTypeId,selectedExamCategory);
        const res = await getAchievementCourses(user.Token,value?value: selectedExamCategory==ExamCategoryType.YKS?examTypeId:ExamType.LGS);
  
        if (res.data.ResponseStatus === 1) {
          console.log("res.data",res.data.ResponseData);
          localStorage.setItem("AchievementCourses", JSON.stringify(res.data.ResponseData));
          //setAllCourseData(res.data.ResponseData);
  
          // if (selectedExamCategory === ExamCategoryType.YKS) {
          //   filterCoursesData(res.data.ResponseData, selectedExamCategory, examTypeId);
          // } else {
          //   filterCoursesData(res.data.ResponseData, selectedExamCategory, ExamType.LGS);
          // }
          
          setCoursesData(res.data.ResponseData)
        } else {
          toast.error("Bir hata oluştu lütfen yetkililerle iletişime geçiniz.");
        }
      }else if(selectedExamCategoryFromStore){
        setSelectedExamCategory(selectedExamCategoryFromStore)
    
        const res = await getAchievementCourses(user.Token,value?value: selectedExamCategoryFromStore==ExamCategoryType.YKS?examTypeId:ExamType.LGS);
  
        if (res.data.ResponseStatus === 1) {
          localStorage.setItem("AchievementCourses", JSON.stringify(res.data.ResponseData));
          //setAllCourseData(res.data.ResponseData);
  
          // if (selectedExamCategoryFromStore === ExamCategoryType.YKS) {
          //   filterCoursesData(res.data.ResponseData, selectedExamCategoryFromStore, ExamType.TYT);
          // } else {
          //   filterCoursesData(res.data.ResponseData, selectedExamCategoryFromStore, ExamType.LGS);
          // }
          setCoursesData(res.data.ResponseData)
        } else {
          toast.error("Bir hata oluştu lütfen yetkililerle iletişime geçiniz.");
        }
      }
       else {
        setIsOpen(true);
      }
    } catch (error) {
      toast.error("Bir hata oluştu lütfen yetkililerle iletişime geçiniz.");
      console.error("Error fetching achievement courses:", error);
    } finally {
      setLoader(false);
    }
  };
  

  const getSubCourseTopics = async (courseId, ExamTypeId) => {
    try {
      setLoader(true); // Set loader to true when starting the request
      const res = await getAchievementSubCourseTopics(user.Token, ExamTypeId, courseId);
      
      console.log("<<<<res>>>>", res);
  
      if (res.data.ResponseStatus === 1) {
        setSubCoursesData(res.data.ResponseData);
        if(res.data.ResponseData.length==0){
          console.log("res.data.ResponseData", res.data.ResponseData);
          toast.error("Bu Ders ile ilgili Kazanımı seçilecek konu yoktur.")
        }
      } else {
        toast.error("bir sorun oluştu lütfen yetkililere ulaşınız.");
      }
    } catch (error) {
      toast.error("Bir hata oluştu lütfen yetkililerle iletişime geçiniz.");
      console.error("Error fetching sub course topics:", error);
    } finally {
      setLoader(false); // Ensure loader is set to false regardless of success or failure
    }
  };

  

  useEffect(() => {
    getCoursesData();
  }, [selectedExamCategory]);
  useEffect(()=>{
    if (selectedCourseId) {
      getSubCourseTopics(
        selectedCourseId,
        selectedExamCategory == 2 ? ExamType.LGS : examTypeId
      );
    }
  },[selectedCourseId])
  const selectExamCategory = (id) => {
    setSelectedExamCategory(id);
    localStorage.setItem("SelectedExamCategory", id);
    setIsOpen(false);
  };

  return (
    <>
      {isTabletOrMobile ? (
        <div>
          <span
            style={{ position: "absolute", top: 10, right: 10 }}
            onClick={() => setNav(!nav)}
          >
            menu
          </span>
          {nav && <SideBar />}
        </div>
      ) : (
        <SideBar />
      )}
 {loader&&<div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Loader size={100} />
          </div>}
          <ToastContainer />
{ratioAlert && (
      <Modal
        isOpen={ratioAlert}
        onClose={() => {
          setRatioAlert(false);
           // Reset selected subtopic
           history.push({
            pathname: "/AchievementSelection",
            state: {
              selectedSubCourseData: selectedSubTopic, // Use selectedSubTopic here
            },
          });

        }}
        onButtonClick={() => {
            setRatioAlert(false)
            setSelectedSubTopic(null)
        }}
        title={"Kazanım Tamamlanmıştır"}
        buttonText={"Başka Konuya Geç"}
        content={"Bu konunun kazanımı %95 oraninda tamamlanmıştır. Diğer konuya gecin."}
        closeButtonText={"Yine de devam et "}
      />
    )}

      <div style={style.container}>
        {isOpen && (
          <div className="modal">
            <div className="modal-content">
              <div style={{ textAlign: "center" }}>
                <h2>Lütfen Sınav Seçimi Yapınız</h2>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <button
                    style={{ padding: 10, width: "30%" }}
                    onClick={() => {
                      selectExamCategory(ExamCategoryType.YKS);
                    }}
                  >
                    YKS
                  </button>
                  <button
                    style={{ width: "30%" }}
                    onClick={() => {
                      selectExamCategory(ExamCategoryType.LGS);
                    }}
                  >
                    LGS
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div style={{ fontWeight: "bold", fontSize: "20px" }}>
          Kazanım Belirleme Paneli
        </div>
        {selectedExamCategory && (
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 10,
              margin: 10,
            }}
          >
            {/* <Toggle
              labels={["YKS", "LGS"]}
              values={[ExamCategoryType.YKS, ExamCategoryType.LGS]}
              initialValue={selectedExamCategory}
              onChange={(value) => {
                setSelectedExamCategory(value);
                filterCoursesData(allCourseData, value, value == ExamCategoryType.LGS ? ExamType.LGS : ExamType.TYT);
                setExamTypeId(value == ExamCategoryType.LGS ? ExamType.LGS : ExamType.TYT)
                localStorage.setItem("SelectedExamCategory",value)
              }}
            /> */}
            <div
              style={{
                borderWidth: 2,
                borderColor: "black",
                borderRadius: 8,
                padding: 10,
                borderStyle: "solid", // Add border style explicitly
                cursor: "pointer",
                alignItems: "center", // Add cursor style to indicate it's clickable
              }}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {getCategoryName(selectedExamCategory)}
              <IconButton style={{ ...style.iconButton }}>
                <EditIcon style={{ ...style.editIcon }} />
              </IconButton>
            </div>
            {selectedExamCategory == 1 && (
              <Toggle
                labels={["TYT", "AYT"]}
                values={[ExamType.TYT, ExamType.AYT]}
                initialValue={examTypeId}
                onChange={(value) => {
                  // filterCoursesData(allCourseData, ExamCategoryType.YKS, value);
                  setExamTypeId(()=>{
                    getCoursesData(value)
                    return value
                  });
                  localStorage.setItem("selectedExamTypeId",value)
                  localStorage.removeItem("selectedCourseId")
                  setSelectedCourseId(null)
                }}
              />
            )}
          </div>
        )}

        {coursesData.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              marginRight: "auto",
              marginLeft: "auto",
              justifyContent: "center",
            }}
          >
            {coursesData.map((data) => {
              return (
                <>
                  <div
                    onClick={() => {
                      localStorage.setItem("selectedCourseId",data.CourseId)
                      setSelectedCourseId(data.CourseId);
                      // history.push({
                      //   pathname: "/questionSubTopicSelection",
                      //   state: {
                      //     courseId: data.CourseId,
                      //     courseName: data.CourseName,
                      //     examType:
                      //       selectedExamCategory == 2 ? ExamType.LGS : examTypeId,
                      //   },
                      // });
                      // getSubCourseTopics(
                      //   data.CourseId,
                      //   selectedExamCategory == 2 ? ExamType.LGS : examTypeId
                      // );
                    }}
                    style={{
                      //background: 'linear-gradient(to right, #FFFFFF, #053EDD)',
                      color: "#FFFFFF",
                      backgroundImage: `url(${CourseBG})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      padding: 30,
                      display: "flex",
                      justifyContent: "space-between",
                      borderRadius: 8,
                      flexWrap: "wrap",
                    }}
                  >
                    <div>{data.Name}</div>
                    <div>{`Tamamlanma Yüzdesi: ${RatioCalculate(
                                  data.CompletedCount,
                                  data.TotalCount
                                )}%`}</div>
                  </div>
                  {selectedCourseId == data.CourseId &&
                    subCoursesData.length > 0 && (
                      <div
                        style={{
                          padding: 10,
                          borderWidth: 1,
                          borderRadius: 8,
                          borderStyle: "solid",
                        }}
                      >
                        {subCoursesData.map((subTopic) => {
                          return (
                            <div
                            key={subTopic.Id}
                              onClick={() => {
                                setSelectedSubTopic(subTopic);
                                goToQuestionPage(subTopic);
                                // history.push({
                                //   pathname: "/AchievementSelection",
                                //   state: {
                                //     selectedSubCourseData: subTopic,
                                //   },
                                // });
                              }}
                              style={{
                                margin: 10,
                                borderWidth: 3,
                                borderRadius: 8,
                                borderStyle: "solid",
                                borderColor:RatioBasedColor(
                                  RatioCalculate(
                                    subTopic.CompletedCount,
                                    subTopic.TotalCount
                                  )
                                ),
                                padding: 10,
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>{subTopic.SubCourseTopicName}</div>
                              <div
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                Tamamlanma yüzdesi:{" "}
                                {RatioCalculate(
                                  subTopic.CompletedCount,
                                  subTopic.TotalCount
                                )}
                                %
                                <span
                                  style={{
                                    marginLeft: 10,
                                    height: "15px",
                                    width: "15px",
                                    backgroundColor: RatioBasedColor(
                                      RatioCalculate(
                                        subTopic.CompletedCount,
                                        subTopic.TotalCount
                                      )
                                    ),
                                    borderRadius: "50%",
                                    display: "inline-block",
                                  }}
                                ></span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                </>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default CoursePage;
